@import '../../variables.scss';

.footer {
  background-color: #171917;
  padding: 60px 0 100px;
  overflow: hidden;
  position: relative;

  @media (min-width: $largebp) {
    padding: 60px 0 140px;
  }

  .width {
    @media (min-width: $largebp) {
      display: flex;
      justify-content: space-between;
    }
  }

  .footer-column + .footer-column {
    margin-top: 40px;

    @media (min-width: $largebp) {
      margin: 0 0 0 60px;
    }
  }

  h2 {
    font-size: 16px;
    color: $white;
    margin-bottom: 25px;

    @media (min-width: $largebp) {
      font-size: 18px;
    }
  }

  li + li {
    margin-top: 5px;
  }

  ul + h2 {
    margin-top: 40px;
  }

  a {
    color: $green;
    display: inline-flex;
    align-items: center;
    transition: 0.2s color;
    font-size: 13px;

    @media (min-width: $largebp) {
      font-size: 14px;
    }

    &:hover {
      color: $white;
    }

    svg {
      margin-left: 10px;
    }
  }
}

.footer-orb {
  position: absolute;
  right: 10px;
  bottom: 100px;
  margin-top: -13px;
  margin-left: -13px;
  animation: 8s floatLarge infinite;

  @media (min-width: $largebp) {
    margin-left: 180px;
    right: auto;
    left: 50%;
    bottom: 90px;
  }
}

.footer-cube {
  position: absolute;
  right: 30px;
  top: 100%;
  margin-top: -109px;
  margin-left: -163px;
  animation: 8s float infinite;

  @media (min-width: $largebp) {
    margin-left: -100px;
    right: auto;
    left: 50%;
  }
}

.footer-built {
  margin-top: 30px;
}
