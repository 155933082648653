.circle-icon {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  box-shadow: 0 0 8px 0 rgba(23, 25, 23, 0.2);
  border: solid 2px currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
}
