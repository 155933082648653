@import './variables.scss';

@font-face {
  font-family: 'Haas Grot Disp';
  src: url('/fonts/hinted-subset-HaasGrotDispR-55Roman.woff2') format('woff2'),
    url('/fonts/hinted-subset-HaasGrotDispR-55Roman.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Haas Grot Disp';
  src: url('/fonts/hinted-subset-HaasGrotDispR-65Medium.woff2') format('woff2'),
    url('/fonts/hinted-subset-HaasGrotDispR-65Medium.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Haas Grot Text';
  src: url('/fonts/hinted-subset-HaasGrotTextR-75Bold.woff2') format('woff2'),
    url('/fonts/hinted-subset-HaasGrotTextR-75Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Haas Grot Text';
  src: url('/fonts/hinted-subset-HaasGrotTextR-65Medium.woff2') format('woff2'),
    url('/fonts/hinted-subset-HaasGrotTextR-65Medium.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Haas Grot Text';
  src: url('/fonts/hinted-subset-HaasGrotTextR-55Roman.woff2') format('woff2'),
    url('/fonts/hinted-subset-HaasGrotTextR-55Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.78;
  font-family: $font;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $greenBg;
  color: $darkGray;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.25;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

input,
select,
button,
textarea {
  appearance: none;
  border: none;
  background: transparent;
  outline: none;
  font-family: $font;
  font-size: 16px;
}

input[type='email'],
input[type='text'],
input[type='password'],
input[type='search'] {
  display: block;
  width: 100%;
}

a,
button,
label,
input[type='submit'],
input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
}

img,
svg {
  display: block;
  max-width: 100%;
}

.red-text {
  color: $red;
}

.green-text {
  color: $green;
}

.dark-gray-text {
  color: $darkGray;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.offscreen {
  position: absolute;
  left: -999em;
}

.width {
  width: 90%;
  max-width: 900px;
  margin: 0 auto;
}

.divider {
  width: 48px;
  height: 2px;
  background: currentColor;
}

.small-divider {
  width: 8px;
  height: 2px;
  background: currentColor;
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -20%);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes floatLarge {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -40%);
  }
  100% {
    transform: translate(0, 0);
  }
}

.fadeInUp {
  transform: translateY(40px);
  opacity: 0;
  transition: 1s opacity, 1s transform;

  .active & {
    opacity: 1;
    transform: none;
  }

  &.delay2 {
    transition-delay: 0.2s;
  }

  &.delay3 {
    transition-delay: 0.3s;
  }

  &.delay4 {
    transition-delay: 0.4s;
  }

  &.delay5 {
    transition-delay: 0.5s;
  }

  &.delay6 {
    transition-delay: 0.6s;
  }

  &.delay7 {
    transition-delay: 0.7s;
  }

  &.delay8 {
    transition-delay: 0.8s;
  }

  &.delay9 {
    transition-delay: 0.9s;
  }

  &.delay10 {
    transition-delay: 1s;
  }

  &.delay11 {
    transition-delay: 1.1s;
  }

  &.delay12 {
    transition-delay: 1.2s;
  }

  &.delay13 {
    transition-delay: 1.3s;
  }

  &.delay14 {
    transition-delay: 1.4s;
  }
}
