@import '../../variables.scss';

.home-built {
  padding-top: 80px;

  @media (min-width: $largebp) {
    padding-top: 150px;
  }
}

.home-built-header {
  text-align: center;

  h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 50px;

    @media (min-width: $largebp) {
      margin-bottom: 70px;
    }
  }
}

.home-built-divider {
  display: inline-flex;
  align-items: center;
  margin: 0 auto 32px;

  @media (min-width: $largebp) {
    margin-bottom: 40px;
  }

  .circle-icon {
    margin: 0 14px;
  }

  .small-orb:first-of-type {
    margin-left: 14px;
  }

  .small-orb:last-of-type {
    margin-right: 14px;
  }

  .small-divider:first-of-type {
    margin-right: 8px;
  }

  .small-divider:last-of-type {
    margin-left: 8px;
  }
}
