@import '../../variables.scss';

.home-stay-header {
  h2 {
    font-size: 32px;
    margin-bottom: 36px;
  }
}

.home-stay-divider {
  display: flex;
  align-items: center;
  color: $green;
  margin-bottom: 32px;

  @media (min-width: $largebp) {
    margin-bottom: 40px;
  }

  .circle-icon {
    box-shadow: 0 0 8px 0 rgba(87, 181, 96, 0.3);
    margin-right: 14px;
  }

  .divider {
    margin: 0 8px 0 14px;
  }
}

.home-stay-flex {
  @media (min-width: $largebp) {
    display: flex;
  }
}

.home-stay-left {
  @media (min-width: $largebp) {
    flex: 1;
    margin-right: 60px;
  }

  p {
    max-width: 553px;

    @media (min-width: $largebp) {
      font-size: 18px;
    }
  }

  .disclaimer-text {
    font-size: 13px;
    color: $lightGray;
    line-height: 1.7;
    max-width: 438px;

    @media (min-width: $largebp) {
      font-size: 14px;
    }
  }
}

.home-stay-right {
  margin-top: 40px;

  @media (min-width: $largebp) {
    width: 305px;
    margin-top: 0;
  }

  li + li {
    margin-top: 24px;
  }
}
