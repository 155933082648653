@import '../../variables.scss';

.home-network {
  padding: 80px 0;

  @media (min-width: $largebp) {
    padding: 120px 0;
  }
}

.home-network-header {
  h2 {
    font-size: 32px;
    margin-bottom: 36px;
  }
}

.home-network-divider {
  display: flex;
  align-items: center;
  color: $green;
  margin-bottom: 32px;

  @media (min-width: $largebp) {
    margin-bottom: 40px;
  }

  .circle-icon {
    box-shadow: 0 0 8px 0 rgba(87, 181, 96, 0.3);
    margin-right: 14px;
  }

  .divider {
    margin: 0 8px 0 14px;
  }
}

.home-network-stats {
  background: $green;
  border-radius: 12px;
  box-shadow: 0 4px 24px 0 rgba(87, 181, 96, 0.3);
  padding: 32px 24px;
  position: relative;

  @media (min-width: $largebp) {
    display: flex;
    align-items: center;
    padding: 40px 30px;
  }

  @media (min-width: $largebp) {
    padding: 50px 20px;
  }

  .divider {
    height: 2px;
    width: 240px;
    background: $black10;
    margin: 20px auto;

    @media (min-width: $largebp) {
      height: 56px;
      width: 2px;
    }
  }
}

.deco-6 {
  position: absolute;
  top: -185px;
  right: 10px;
  animation: 8s float infinite;
}

.deco-7 {
  position: absolute;
  top: -130px;
  right: 35px;
  animation: 6s float infinite;
}

.deco-8 {
  position: absolute;
  top: -65px;
  right: -95px;
  animation: 10s float infinite;
}

.home-network-stat {
  text-align: center;

  @media (min-width: $largebp) {
    text-align: center;
    width: calc(20% - 2px);
  }

  .inner {
    @media (min-width: $largebp) {
      margin: 0 auto;
      display: inline-block;
      text-align: left;
      min-width: 110px;
    }
  }

  h3 {
    font-weight: 700;
    color: $white;
    font-size: 32px;
    min-width: 10px;

    @media (min-width: $largebp) {
      font-size: 40px;
    }
  }
}

.network-stat-name {
  font-size: 14px;
  color: $white50;
}

.home-network-columns {
  margin-top: 40px;

  @media (min-width: $largebp) {
    display: flex;
    margin-top: 60px;
  }
}

.home-network-column {
  @media (min-width: $largebp) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  & + & {
    margin-top: 40px;

    @media (min-width: $largebp) {
      margin: 0 0 0 30px;
    }
  }

  &.left p {
    color: $gray;

    @media (min-width: $largebp) {
      font-size: 18px;
    }
  }

  &.left p + p {
    margin-top: 30px;

    @media (min-width: $largebp) {
      margin-top: 40px;
    }
  }

  .more {
    color: $green;
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    transition: 0.2s color;

    &:hover {
      color: $black;
    }

    @media (min-width: $largebp) {
      font-size: 14px;
    }

    svg {
      margin-left: 10px;
      transition: 0.2s transform, 0.2s color;
    }

    &:hover svg {
      transform: translateX(2px);
      color: $black;
    }
  }

  .more-faq {
    margin-left: 48px;
  }
}
