// Colors
$white: #fff;
$white90: rgba(255, 255, 255, 0.9);
$white80: rgba(255, 255, 255, 0.8);
$white70: rgba(255, 255, 255, 0.7);
$white60: rgba(255, 255, 255, 0.6);
$white50: rgba(255, 255, 255, 0.5);
$white40: rgba(255, 255, 255, 0.4);
$white30: rgba(255, 255, 255, 0.3);
$white20: rgba(255, 255, 255, 0.2);
$white10: rgba(255, 255, 255, 0.1);

$black: #000;
$black90: rgba(0, 0, 0, 0.9);
$black80: rgba(0, 0, 0, 0.8);
$black70: rgba(0, 0, 0, 0.7);
$black60: rgba(0, 0, 0, 0.6);
$black50: rgba(0, 0, 0, 0.5);
$black40: rgba(0, 0, 0, 0.4);
$black30: rgba(0, 0, 0, 0.3);
$black20: rgba(0, 0, 0, 0.2);
$black10: rgba(0, 0, 0, 0.1);

$greenBg: #f1f7f2;
$green: #57b560;
$greenHover: #4ea156;

$darkGray: #171917;
$gray: #5a5e5b;
$lightGray: #969a97;
$red: #e65c5c;

// fonts
$font: 'Haas Grot Text', serif;
$font2: 'Haas Grot Disp', serif;

// Breakpoints
$tinybp: 374px;
$smallbp: 500px;
$smbp: 640px;
$mediumbp: 680px;
$largebp: 840px;
$xlargebp: 1100px;

// Transitions
$fadeOff: left 0s 0.15s, opacity 0.15s;
$fadeOn: left 0s, opacity 0.15s;
