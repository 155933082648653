@import '../../variables.scss';

.app {
  position: relative;
  overflow-x: hidden;
  max-width: 100%;
}

.top-swoosh {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  margin-left: 340px;
  width: 622px;
  max-width: none;

  @media (min-width: $largebp) {
    margin-left: 380px;
  }
}

.home-white {
  background: $white;
  padding: 80px 0;

  @media (min-width: $largebp) {
    padding: 120px 0;
  }
}
