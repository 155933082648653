@import '../../variables.scss';

.home-stay-form {
  max-width: 342px;
  margin: 36px 0 24px;

  .relative {
    position: relative;
  }

  .message {
    font-size: 12px;
    margin-top: 10px;
    color: $lightGray;

    &.red-text {
      color: $red;
    }
  }

  input[type='email'] {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(90, 94, 91, 0.1);
    border: solid 1px #cbd3cd;
    padding: 12px 0 12px 16px;
    transition: 0.2s border-color;

    &:hover {
      border-color: rgb(143, 143, 143);
    }

    &:focus {
      border-color: $green;
    }
  }

  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid #cbd3cd;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
    margin: -2px 8px 0 0;
    background-image: url('/images/green-check.svg');
    background-repeat: no-repeat;
    background-size: 28px;
    background-position: top -300px center;

    &:checked {
      background-position: top -3px center;
    }
  }

  button {
    font-weight: 400;

    @media (min-width: $largebp) {
      font-size: 16px;
    }
  }

  .email-error {
    font-size: 12px;
    margin-top: 8px;
    color: $red;
  }
}

.home-stay-form.light {
  input[type='email'] {
    box-shadow: 0 2px 4px 0 rgba(90, 94, 91, 0.1);
    border-color: $white50;
    color: $white;

    &:hover {
      border-color: $white;
    }

    &:focus {
      border-color: $green;
    }
  }

  input[type='checkbox'] {
    border-color: $white50;
  }

  label {
    color: $white50;
  }

  .message {
    color: $white30;

    &.red-text {
      color: $red;
    }
  }
}

.home-form-stay-existing {
  margin: 15px 0 20px 0;

  label {
    color: $darkGray;
  }
}
