@import '../../variables.scss';

.faq {
  display: flex;
  margin-bottom: 30px;
}

.faq-text {
  flex: 1;
  margin-left: 16px;

  h3 {
    font-weight: 700;
    color: $gray;
    margin-bottom: 10px;
    line-height: 1.78;
    font-size: 16px;

    @media (min-width: $largebp) {
      font-size: 18px;
    }
  }

  p {
    color: $lightGray;
    line-height: 1.7;
    font-size: 13px;

    @media (min-width: $largebp) {
      font-size: 14px;
    }
  }
}

.faq-dash {
  height: 2px;
  width: 32px;
  background: $green;
  box-shadow: 0 0 8px 0 rgba(87, 181, 96, 0.3);
  margin-top: 16px;
}
