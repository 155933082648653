@import '../../variables.scss';

.upload-file {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.upload-file-icon {
  display: block;
  min-width: 36px;
  width: 36px;
  max-width: 36px;
}

.upload-file-text {
  display: block;
  margin: 0 18px;
  flex: 1;

  h3 {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 400;

    @media (min-width: $largebp) {
      font-size: 18px;
    }
  }

  p {
    font-size: 14px;
    color: $lightGray;
  }

  a {
    color: $green;
  }

  .url {
    word-break: break-word;

    &:hover {
      text-decoration: underline;
    }
  }
}

.upload-file-loading,
.upload-file-copy {
  margin-left: auto;
}

.upload-file-copy {
  position: relative;

  &:hover {
    color: $green;
  }

  textarea {
    position: absolute;
    left: -999em;
  }
}

.upload-file-copy-tooltip {
  position: absolute;
  bottom: 100%;
  margin-bottom: 10px;
  transform: translateX(-50%);
  color: $white;
  background: rgba(23, 25, 23, 0.9);
  font-size: 14px;
  white-space: nowrap;
  line-height: 32px;
  box-shadow: 0 2px 8px 0 rgba(23, 25, 23, 0.2);
  border-radius: 4px;
  width: 140px;
  text-align: center;
  opacity: 0;
  left: -9999em;
  transition: $fadeOff;

  .upload-file-copy:hover & {
    left: 50%;
    opacity: 1;
    transition: $fadeOn;
  }

  &::after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: rgba(23, 25, 23, 0.9) transparent transparent transparent;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    content: '';
    position: absolute;
    box-shadow: 0 2px 8px 0 rgba(23, 25, 23, 0.2);
  }
}
