@import '../../variables.scss';

.button {
  display: inline-block;
  background: $green;
  color: $white;
  border-radius: 4px;
  box-shadow: 0 0 16px 0 rgba(87, 181, 96, 0.1);
  line-height: 48px;
  font-weight: bold;
  padding: 0 32px;
  transition: 0.2s background-color, 0.2s color, 0.2s transform;

  @media (min-width: $largebp) {
    font-size: 18px;
  }

  &:hover {
    background-color: $greenHover;
    transform: translateY(-2px);
  }
}
